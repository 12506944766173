<template lang="pug">
.page
  .section
    .title ddd
    .content this is a vue page file with basic api
    .content this is page content
    .content
  .section
    .title router list
    .content {{routerList}}
    .content 
      el-button(type="primary" @click="submitRouterList") submitRouterList
</template>

<script setup>
import { inject, onMounted, ref, computed } from 'vue'
import { routes } from '@/plugin/router'

const { fetch } = inject('global')
const routerList = computed(() => routes)

function handlePing() {
  fetch.get('/basic/ping')
}
function submitRouterList() {
  fetch.post('/basic/auth/webProject/submitRouter', {
    project: 'homebrew',
    routerList: routerList.value
  })
}
</script>

<style lang="less" scoped>
.section {
  --gutter: 20px;
  border: 1px solid var(--color-primary);
  margin: var(--gutter);
  border-radius: var(--gutter);
  overflow: hidden;
  .title {
    text-align: center;
    padding: 10px;
    background: var(--color-primary);
    color: white;
    font-size: 20px;
  }
  .content {
    padding: var(--gutter);
  }
}
</style>
